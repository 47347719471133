<template>
  <div id="app">
    <div class="header"></div>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
export default {
  name: "default"
}
</script>

<style lang="less">
@import (reference) "~@telenor/tokens/build/less/variables.less";
@import "~@telenor/tokens/build/less/fontface";

@font-family-regular: "Helvetica Neue", Arial, sans-serif;

html, body, #__nuxt, #__layout {
  height: 100%;
}

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

html {
  font-family: @font-family-regular;
}

#app {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  background: #fff;
  height: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
}

.header {
  width: 100%;
  height: 6rem;

  border-bottom: 1px solid #f5f5f5;
  background-image: url(./assets/telenor-logo-flat-blue.svg);
  background-size: 11.5rem 3rem;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  width: 22.5rem;
  margin-left: auto;
  margin-right: auto;
}

</style>
