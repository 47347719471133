<template>
  <form-wrapper style="width:600px; min-width: 325px;" @submit="handleGoClick">
    <heading size="xl" class="heading">Debugger</heading>
    <div>
      <label>Authority {{ environment.label }}
        <input type="text" id="txtAuthority" v-model="options.overrideAuthority" :placeholder="environment.authority"/>
      </label>
    </div>
    <div>
      <label>Client ID
        <input type="text" id="txtClientId" v-model="options.clientId"/>
      </label>
    </div>
    <div>
      <label>Callback URI
        <input type="text" id="txtRedirectUri" v-model="options.overrideCallbackUrl"
               :placeholder="callbackUrl"/>
      </label>
    </div>
    <div>
      <label>
        Scope
        <input type="text" id="txtScopes" v-model="options.scope"/>
      </label>
    </div>
    <div>
      <label>
        Login hint
        <input type="text" id="txtLoginHint" v-model="options.loginHint"/>
      </label>
    </div>
    <div>
      <label>
        Force IdP
        <input type="text" id="txtForceIdp" v-model="options.idp" placeholder="Can be left blank"/>
      </label>
    </div>
    <div>
      <label>
        Authentication Context Class Reference (ACR)
        <input type="text" id="txtAcr" v-model="options.acr" placeholder="Can be left blank"/>
      </label>
    </div>
    <div>
      <label>
        Login Context
        <input type="text" id="txtLoginContext" v-model="options.loginContext" placeholder="Can be left blank"/>
      </label>
    </div>
    <div>
      <label>
        Language
        <input type="text" id="txtLanguage" v-model="options.language" placeholder="Can be left blank"/>
      </label>
    </div>
    <div>
      <label>
        Aloha Token
        <input type="text" id="alohaToken" v-model="options.alohaToken" placeholder="Can be left blank"/>
      </label>
    </div>
    <div>
      <label>
        Code Challenge
        <input type="text" id="codeChallenge" v-model="options.codeChallenge" placeholder="Can be left blank"/>
      </label>
    </div>
    <div>
      <label>
        Client secret
        <input type="password" id="clientSecret" v-model="options.clientSecret" placeholder="Can be left blank"/>
      </label>
    </div>
    <div>
      <label>
        Prompt mode
        <input type="text" id="promptMode" v-model="options.promptMode" placeholder="Can be left blank, none, login"/>
      </label>
    </div>
	    <div>
      <label>
        DPoP Proof Token
        <input type="text" id="dpop" v-model="options.dpopToken" placeholder="Can be left blank"/>
      </label>
    </div>
    <div>
      <button type="button" id="btnAuthorize" v-on:click="handleGoClick">Go</button>
    </div>
    <div>
      <button type="button" class="clear" v-on:click="clearLs">Clear</button>
    </div>
    <div>
      <button type="button" class="log-out" v-on:click="logOut">Log out</button>
    </div>
    <div>
      <button type="button" v-if="user" class="meta" v-on:click="selfService">Self-service</button>
    </div>
  </form-wrapper>
</template>

<script>
import FormWrapper from "./FormWrapper";

import {
  Heading
} from '@telenor/components-legacy/src/components';
import {UserManager, WebStorageStateStore} from 'oidc-client';
import {EnvironmentService} from "@/environment";

export default {
  name: "DebuggerConfig",
  components: {
    FormWrapper, Heading
  },
  props: {
    model: Object,
    user: Object
  },
  computed: {
    callbackUrl() {
      return `${this.environment.value}/callback`;
    },
    postLogoutUrl() {
      return `${this.environment.value}/?logout=ok`;
    }
  },
  data() {
    let lsOptions = localStorage.getItem('options');
    let options = lsOptions ? JSON.parse(lsOptions) : this.getDefaultOptions();

    return {
      environment: {},
      options: options
    }
  },
  methods: {
    async handleGoClick() {
      localStorage.setItem('options', JSON.stringify(this.options));
      let mgr = this.getUserManager();

      if(this.options.dpopToken){
        const codeVerifier = this.generateCodeVerifier();
        await this.generateCodeChallenge(codeVerifier)
          .then(codeChallenge => {
            this.options.code_challenge = codeChallenge;
        })
          .catch(error => {
          console.error('Error generating code challenge:', error);
          });
        
        localStorage.setItem('dpop_token', this.options.dpopToken);
        localStorage.setItem('code_verifier', codeVerifier);
        localStorage.setItem('state', this.generateCodeVerifier());
        return this.customAuthorize(mgr);
      }
      else{
        await mgr.signinRedirect({
          login_hint: this.options.loginHint,
          acr_values: this.options.acr + (this.options.idp ? ` idp:${this.options.idp}` : ``),
          extraQueryParams: {
            amr: this.options.amr || "",
            ui_locales: this.options.language || "no en",
            context: this.options.loginContext || "login",
            aloha_token: this.options.alohaToken || "",
            code_challenge: this.options.codeChallenge || ""
          }
        });
      }
    },
    
    getDefaultOptions() {
      return {
        overrideAuthority: '',
        clientId: "demo-client",
        loginHint: "99966634",//"90134687",
        overrideCallbackUrl: '',
        acr: '',
        idp: '',
        responseType: 'code',
        scope: "openid profile ial2",
        dpop: null
      };
    },
    clearLs() {
      window.localStorage.clear();
      this.options = this.getDefaultOptions();
    },
    async selfService() {
      let response = await fetch(
          `/api/meta/v1/self-service`,
          {
            headers: {
              Authorization: `Bearer ${this.user.access_token}`
            }
          }
      );

      console.log(response);
    },
    async logOut() {
      if(this.options.dpopToken){
        this.clearLs();
      }
      let mgr = this.getUserManager();
      await mgr.signoutRedirect();
    },
    getUserManager() {
      return new UserManager({
        authority: this.options.overrideAuthority || this.environment.authority,
        client_id: this.options.clientId,
        client_secret: this.options.clientSecret || "",
        redirect_uri: this.options.overrideCallbackUrl || this.callbackUrl,
        response_type: 'code',
        scope: this.options.scope,
        post_logout_redirect_uri: this.postLogoutUrl,
        prompt: this.options.promptMode || "",
        userStore: new WebStorageStateStore({store: window.localStorage})
      });
    },
    customAuthorize(userManager) {
      const queryParams = new URLSearchParams();
      queryParams.append('client_id', userManager.settings.client_id);
      queryParams.append('redirect_uri', userManager.settings.redirect_uri);
      queryParams.append('response_type', 'code');
      queryParams.append('scope', userManager.settings.scope);
      queryParams.append('code_challenge', this.options.code_challenge);
      queryParams.append('code_challenge_method', 'S256');
      queryParams.append('state', localStorage.getItem('state'));
      queryParams.append('ui_locales', this.options.language || "no en");
      queryParams.append('context', this.options.loginContext || "login");
      if(this.options.acr || this.options.idp)
      queryParams.append('acr_values', this.options.acr + (this.options.idp ? ` idp:${this.options.idp}` : ``));
      if(userManager.settings.prompt)
        queryParams.append('prompt', userManager.settings.prompt);
      if(this.options.loginHint)
        queryParams.append('login_hint', this.options.loginHint);
      if(this.options.alohaToken)
        queryParams.append('aloha_token', this.options.loginHint);

      const authorizeUrl = `${this.environment.authority}/connect/authorize?${queryParams.toString()}`;
      window.location.href = authorizeUrl;
    },
    generateCodeVerifier() {
      const codeVerifierLength = 64;
      const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
      let codeVerifier = '';

      for (let i = 0; i < codeVerifierLength; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        codeVerifier += charset.charAt(randomIndex);
      }
      return codeVerifier;
    },
    async generateCodeChallenge(codeVerifier) {
      const encoder = new TextEncoder();
      const codeVerifierBytes = encoder.encode(codeVerifier);
      const hashedBytes = await crypto.subtle.digest('SHA-256', codeVerifierBytes);
      const codeChallenge = this.base64UrlEncode(hashedBytes);
      return codeChallenge;
    },
    base64UrlEncode(input) {
      const base64 = btoa(String.fromCharCode.apply(null, new Uint8Array(input)));
      return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    }
  },
  async mounted() {
    this.environment = await EnvironmentService.getEnvironment();
  }
}
</script>

<style lang="less" scoped>
@import (reference) "~@telenor/tokens/build/less/variables.less";

div {
  margin: 1.2em 0;

  select, label, input, button {
    width: 90%;
    position: relative;
    font-family: @asset-font-telenor-light-name;
    margin: 0;
  }

  button {
    background: #007ad0;
    color: #fff;
    border-radius: 10px;
    margin: 20px auto 0px;
    display: block;
  }

  button#btnAuthorize:active {
    background: #5395c5;
  }

  button.log-out {
    background: #fc5145;
  }

  button.log-out:active {
    background: #f88a6c;
  }

  button.clear {
    background: #ff9326;
  }

  button.clear:active {
    background: #f1b883;
  }

  button.meta {
    background: #77b9e9;
  }

  select, button {
    width: 100%;
    padding: 8px 0;
    font-size: 18px;
    border: none;
    border-bottom: 1px solid #d6d6d6;
    caret-color: #19aaf8;
  }

  input {
    &[type="text"],
    &[type="email"],
    &[type="tel"],
    &[type="url"],
    &[type="password"],
    &[type="date"] {
      width: 100%;
      padding: 8px 0;
      font-size: 18px;
      border: none;
      border-bottom: 1px solid #d6d6d6;
      caret-color: #19aaf8;

      &:focus {
        outline: none;
        border-bottom: 1px solid #19aaf8;
      }
    }
  }
}

.heading {
  margin: 50px auto;
  display: block;
  text-align: center;
}
</style>
