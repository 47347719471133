export class EnvironmentService {
  public static async getEnvironment() {
    let result = await fetch('/env', {method: 'GET'});

    if (result.ok) {
      return await result.json();
    }

    return {
      value: 'https://id-test.telenor.no',
      authority: 'https://id-test.telenor.no',
      label: "STAGING"
    }
  }
}
