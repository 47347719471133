<template>
    <device-config/>
</template>

<script>
    import DeviceConfig from "../../components/DeviceConfig";

    export default {
        name: "Device",
        head(){
            return {
                title: "Device"
            }
        },
        components: {DeviceConfig},
    }
</script>

