<template>
  <div class="debugger">
    <debugger-config style="position: sticky; top: 0;" :user="user"></debugger-config>
    <debugger-output :user="user"></debugger-output>
  </div>
</template>

<script>
import DebuggerConfig from "../../components/DebuggerConfig";
import DebuggerOutput from "../../components/DebuggerOutput";
import {UserManager, WebStorageStateStore} from "oidc-client";
import {EnvironmentService} from "@/environment";
import axios from 'axios'

export default {
  name: "Callback",
  components: {DebuggerOutput, DebuggerConfig},
  head() {
    return {
      title: "Debugger callback"
    }
  },
  data() {
    let options = JSON.parse(localStorage.getItem('options'));
    return {
      options: options,
      user: null,
      dpopToken: null,
      codeVerifier: null,
      clientSecret: null,
      environment: {}
    }
  },
  async mounted() {
    let storedAuthority = localStorage.getItem('authority');
    if (storedAuthority)
      this.environment = storedAuthority;
    else
      this.environment = await EnvironmentService.getEnvironment();

    if (this.$route.query.state && this.$route.query.code) { // Callback
      this.dpopToken = localStorage.getItem('dpop_token');
      if(this.dpopToken){
        let mgr = this.getUserManager();
        this.codeVerifier = localStorage.getItem('code_verifier');
        this.clientSecret = mgr.clientSecret;
        this.performCustomCallback();
      }
      else{
        let mgr = this.getUserManager();
        await mgr.signinRedirectCallback().then(user => {
        this.user = user;
        window.console.log(user);
        }).catch(err => {
        window.console.error(err);
      });
      }
    }
    else if (this.$route.query.error) {
      alert(`Error: ${this.$route.query.error} - ${this.$route.query.error_description}`)
    }
    else {
      window.console.error("Missing state param/code in return url?!");
    }
  },
  methods: {
    getUserManager() {
      return new UserManager({
        response_mode: 'query',
        loadUserInfo: true,
        userStore: new WebStorageStateStore({store: window.localStorage})
      });
    },
    performCustomCallback() {
      const searchParams = new URLSearchParams(window.location.search);
      const authCode = searchParams.get('code');

      if (authCode) {
        this.performCustomTokenCall(authCode)
          .then(response => {
            this.user = {
              access_token: response.access_token,
              id_token: response.id_token,
              refresh_token: response.refresh_token,
              token_type: response.token_type, 
              scope: response.scope,
              expires_at: response.expires_in,
              profile: {}
            };

        this.performCustomUserInfoCall()
          .then(userInfoResponse => {
            this.user.profile = userInfoResponse;
            window.console.log(this.user);
          })
          .catch(error => {
          window.console.error(error);
          });
        })
        .catch(error => {
        window.console.error(error);
        });
      }
      else {
        console.error('No authorization code found in URL');
      }
    },
    performCustomTokenCall(authCode) {
      const tokenEndpoint = `${this.environment.authority}/connect/token`;

      const body = new URLSearchParams();
      body.append('client_id', this.options.clientId);
      body.append('redirect_uri', `${this.environment.value}/callback`);
      body.append('grant_type', 'authorization_code');
      body.append('code', authCode);
      if(this.options.clientSecret){
        body.append('client_secret', this.options.clientSecret);
      }
      if(this.codeVerifier){
        body.append('code_verifier', this.codeVerifier);
      }

      return axios.post(tokenEndpoint, body, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'DPoP': this.dpopToken
        }
      })
        .then(response => response.data)
        .catch(error => {
            throw new Error('Token call failed');
        });
    },
    performCustomUserInfoCall(){
      return axios.get(`${this.environment.authority}/connect/userinfo`, {
          headers: {
            'Authorization': `Bearer ${this.user.access_token}`
          }
      })
      .then(response => response.data)
      .catch(error => {
          throw new Error('UserInfo call failed');
      });
    }
  }
}
</script>

<style scoped>
.debugger {
  display: flex;
  align-items: flex-start;
}
</style>
