import Vue from 'vue';
import VueRouter from 'vue-router';
import Callback from '../views/debugger/Callback.vue';
import Debugger from '../views/debugger/Debugger.vue';
import Device from '../views/debugger/Device.vue';
import qs from 'qs';

Vue.use(VueRouter);

const routes = [
 {
    path: '/',
    name: 'debugger',
    component: Debugger,
    props: true,
  },
  {
    path: '/callback',
    name: 'oauth-callback',
    component: Callback,
    props: true,
  },
  {
    path: '/devicepoc',
    name: 'device',
    component: Device,
    props: true,
  }
];

const router = new VueRouter({
  mode: 'history',
  base: "/",
  parseQuery: (query: any): object => { // IDE doesn't show prop as active, but it is
    let parsedQs = qs.parse(query);
    return parsedQs;
  },
  stringifyQuery(query: any): string {
    let result = qs.stringify(query, {encode: false});
    return result ? ('?' + result) : '';
  },
  routes,
});

export default router;
