<template>
  <form-wrapper width="600">
  <div>
      Scopes
      <input type="text" id="txtScopes" v-model="options.scopes"/>
      <button type="button" v-on:click="deviceAuthorization">Start Device Flow Demo</button>
      <button type="button" v-on:click="deviceToken">Poll For Token</button>
  </div>
      <div class="title" id="authorized" v-if="tokenResponse && !tokenResponse.error">Account Connected Successfully</div>
      <div class="title" v-else-if="userCode">Connect Your Account</div>
      
      
      <div class="stepsText" v-if="userCode">1. Scan QR or enter the following link on your device:</div>
      <div class="stepsText" id="url" v-if="userCode"> {{this.verificationUri}} </div>
      <div class="qrBox" v-if="userCode"></div>
      <div class="stepsText" v-if="userCode">2. Enter the activation code:</div>
      <div class="stepsText" id="code" v-if="userCode">{{this.userCode}}</div>

<label><u>Token Response</u></label>

    <div v-if="tokenResponse">
      
        <json-viewer v-if="tokenResponse.error"
          :value="{error: tokenResponse.error}"
          :expand-depth="1"
          :preview-mode="true"
          :copyable="true"
        ></json-viewer>


        <json-viewer v-if="tokenResponse.access_token && tokenResponse.id_token"
          :value="{jwt_access_token: parseJwt(tokenResponse.access_token), jwt_id_token: parseJwt(tokenResponse.id_token)}"
          :expand-depth="5"
      ></json-viewer>
        <json-viewer v-if="tokenResponse.access_token && tokenResponse.id_token"
          :value="{access_token: tokenResponse.access_token, id_token: tokenResponse.id_token}"
          :expand-depth="1"
          :preview-mode="true"
          :copyable="true"
        ></json-viewer>
        <json-viewer v-if="tokenResponse.refresh_token"
          :value="{refresh_token: tokenResponse.refresh_token}"
          :expand-depth="1"
          :preview-mode="true"
          :copyable="true"
        ></json-viewer>
      </div>

  </form-wrapper>
</template>



<script>
import {
  Heading
} from '@telenor/components-legacy/src/components';
import FormWrapper from "./FormWrapper";
import JsonViewer from 'vue-json-viewer/ssr.js';
import {EnvironmentService} from "@/environment";
import 'vue-json-viewer/style.css'


export default {
  name: "DeviceConfig",
  components: {
    FormWrapper, JsonViewer
  },

  data() {
    let options = this.getDefaultOptions();
    return{
    userCode: null,
    deviceCode: null,
    verificationUrl: null,
    tokenResponse: null,
    options: options
    }
  },

  async mounted() {
    this.environment = await EnvironmentService.getEnvironment();
  },
  methods: {
    getDefaultOptions() {
      return {
        clientId: 'deviceflow-client',
        scopes: 'openid profile',
        grantType: 'urn:ietf:params:oauth:grant-type:device_code'
      };
    },
    parseJwt(jwt) {
      let base64Url = jwt.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    },

    	async deviceAuthorization() {
      this.tokenResponse = null;
      let response = await fetch(
          `${this.environment.authority}/connect/deviceauthorization`,
          {
            method: 'POST',
			      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			      body: `client_id=${this.options.clientId}&scope=${this.options.scopes}`
			   }
		).then(result => result.json())
    .then(data => { this.userCode = data.user_code, this.deviceCode = data.device_code, this.verificationUri = data.verification_uri});
	},

  async deviceToken() {
    this.tokenResponse = null;
      let response = await fetch(
          `${this.environment.authority}/connect/token`,
          {
            method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: `client_id=${this.options.clientId}&grant_type=${this.options.grantType}&device_code=${this.deviceCode}`
			   }
		).then(result => result.json())
    .then(data => { this.tokenResponse = data});
	},
    }
}
</script>

<style lang="less" scoped>
@import (reference) "~@telenor/tokens/build/less/variables.less";

div {
  margin: 1.2em 0;

  select, label, button {
    width: 15%;
    position: relative;
    font-family: @asset-font-telenor-light-name;
    font-size: 18px;
    text-align: center;
    background: #007ad0;
    color: #fff;
    border-radius: 10px;
    margin: 20px 10px;
    padding: 8px 0;
    display: block;
    border-bottom: 1px solid #d6d6d6;
    caret-color: #19aaf8;
  }

  label{
    font-size: 24px;
    color: black;
    background: none;
    border: none;
  }

  input{
    width: 30%;
    position: relative;
    font-family: @asset-font-telenor-light-name;
    margin: 0;
  }

  .title{
    width: 30%;
    height: 40px;
    position: relative;
    font-family: @asset-font-telenor-light-name;
    background: orange;
    color: #fff;
    border-radius: 10px;
    margin: 20px auto 0px;
    display: block;
    padding: 8px 0;
    font-size: 22px;
    border-bottom: 1px solid #d6d6d6;
    text-align: center;
  }
  .title#authorized{
    background: lightgreen;
  }

    .stepsText{
    width: 30%;
    height: 30px;
    position: relative;
    display: block;
    font-family: @asset-font-telenor-light-name;
    font-size: 16px;
    background: #007ad0;
    border-radius: 5px;
    color:white;
    text-align: center;
    padding: 8px 0;
    margin: 20px auto 0px;
  }
  .stepsText#url{
    height: 15px;
    font-size: 22px;
    color: black;
    background: none;
  }

  .stepsText#code{
    height: 40px;
    font-size: 70px;
    color: black;
    background: none;
  }

  .qrBox{
   width: 100%;
   height: 350px;
   background-image: url(../assets/device_qr_demo.svg);
   background-size: 32rem 23rem;
   background-position: 50% 100%;
   background-repeat: no-repeat;
   position: relative;
   display: block;
  }
}

.heading {
  margin: 50px auto;
  display: block;
  text-align: center;
}
</style>
